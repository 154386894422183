import * as React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { AppState } from '@sleekly-ui/AppState';
import { AppPage } from '@sleekly-ui/layout/AppPage';

import { UserHome } from './UserHome';
import { Login } from './alchemix/Login';


const router = createBrowserRouter([
    {
        path: "/",
        element: <UserHome></UserHome>,
    },
    {
        path: "/login",
        element: <Login defaultRoute="/">
            <div className='text-center font-bold my-4 text-2xl'>MedScrib</div>
        </Login>,
    },
]);

type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const App: React.FC<Props> = ({ }: Props) => {

    AppState.pushState('app-theme', 'dark');

    return <AppPage>
        
        <React.StrictMode>
            <RouterProvider router={router} />
        </React.StrictMode>
    </AppPage>
}