import * as React from "react";
import * as moment from "moment";

import { AlchemixFlux } from "../alchemix/Alchemix";

import { Project } from "@medscrib-core/Project";
import { SearchEntrySummary } from "@medscrib-core/SearchEntrySummary";
import { UserSearchHistory } from "@medscrib-core/UserSearchHistory";
import { GetSearchHistory } from "@medscrib-core/GetSearchHistory";

import { List } from "@sleekly-ui/components/List";
import { ScreenTitle } from "@sleekly-ui/components/ScreenTitle";
import { generateUUID } from "@sleekly-ui/utils/uuid";
import { SearchIcon } from "@sleekly-ui/icons/SearchIcon";
import { ChevronRightIcon } from "@sleekly-ui/icons";

type Props = {
    project: Project,
    onPerformSearch: (terms: SearchEntrySummary) => void
} & React.HTMLAttributes<HTMLDivElement>

export const SearchHistory: React.FC<Props> = ({ project, onPerformSearch }: Props) => {

    const [historyEntries, setHistoryEntries] = React.useState<SearchEntrySummary[]>([]);

    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (project) {
            loadHistory(project);
        }
    }, [project]);

    const loadHistory = async (project: Project) => {
        setLoading(true);

        const event: GetSearchHistory = {
            id: `${project.id}-${generateUUID()}`,
            kind: 'GetSearchHistory',
            project_id: project.id
        };

        const res = await AlchemixFlux.postEvent("medscrib", event);
        const searchHistory: UserSearchHistory = res.getFirst("UserSearchHistory");

        setHistoryEntries(searchHistory.search_summaries);
        setLoading(false);
    };

    const historyItemRenderer = (onPerformSearch) => (item: SearchEntrySummary) => {
        const performSearch = () => {

            onPerformSearch(item);
        }
        return <div className="border-b border-base-content border-opacity-50 p-2 cursor-pointer"
            onClick={performSearch}>
            <div className="flex">
                <div>
                    <div className="text-lg flex items-center space-x-2">
                        <span>{item.terms}</span>
                        {
                            item.refined_terms.map(term => {
                                return <div key={term} className="flex items-center space-x-2">

                                    <ChevronRightIcon></ChevronRightIcon>
                                    <span>{term}</span>
                                </div>
                            })
                        }
                    </div>
                    {/* <div className="text-xs opacity-75">{item.parent_search_id} ° {item.search_id}</div> */}
                    <div className="text-xs opacity-75">{moment(item.date).format('DD MMM YYYY - HH:MM')}</div>
                </div>
                <div className="flex-1"></div>

                <div className="flex flex-col items-center justify-center">
                    <div >{item.selected_items} / {item.total_results}</div>
                    <div className="text-xs opacity-75">Executed: {item.execution_count} times</div>
                </div>
            </div>

        </div>
    }

    return <div className="flex-1 flex flex-col w-full max-w-screen-md overflow-hidden">
        <ScreenTitle>Search History</ScreenTitle>
        <div className='flex-1 overflow-hidden flex flex-col'>
            <List
                className="max-w-screen-md"
                hideBorders={true}
                loading={loading}
                items={historyEntries}
                renderItem={historyItemRenderer(onPerformSearch)}
                emptyIllustration={() => {
                    return <div>
                        <SearchIcon className="h-10 w-10"></SearchIcon>
                    </div>
                }}
                emptyMessage={'Welcome to your project\'s space'}
                emptyMessageDetails={'Start by typing a search query'}
                loadingMessage="Loading your history"
            ></List>
        </div>

    </div>
}