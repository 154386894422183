import * as React from "react";

import { Project } from "@medscrib-core/Project";
import { AppState } from "@sleekly-ui/AppState";
import { PageSwitcher } from "@sleekly-ui/layout/PageSwitcher";
import { ProjectsPage } from "./project/ProjectsPage";
import { CloseIcon, StackIcon } from "@sleekly-ui/icons";
import { PROJECT_DIALOG, ProjectDialog } from "./project/ProjectDialog";
import { openDialog } from "@sleekly-ui/layout/Dialog";
import { ProjectHome } from "./project/ProjectHome";
import { logOut, useLoggedInUser } from "./alchemix/Login";
import { UserAvatar } from "@sleekly-ui/components/UserAvatar";
import { LogoutIcon } from "@sleekly-ui/icons/LogoutIcon";
import { DocumentContentDialog } from "./collection/DocumentContentDialog";

export const CURRENT_PROJECT = "CURRENT_PROJECT";

type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const UserHome: React.FC<Props> = ({ }: Props) => {

    const user = useLoggedInUser();

    const [currentProject, setCurrentProject] = AppState.useState<Project>(CURRENT_PROJECT);

    const [currentPage, setCurrentPage] = React.useState<string>("Projects");

    React.useEffect(() => {
        if (currentProject) {
            setCurrentPage("ProjectHome");
        } else {
            setCurrentPage("Projects");
        }
    }, [currentProject])


    const showProjectDialog = () => {
        openDialog(PROJECT_DIALOG, currentProject);
    }

    const onLogout = () => {
        logOut();
        setCurrentProject(null);
    }

    const projectTitleStyle = currentProject?.name === "Untitled Project" ? 'italic font-thin' : 'font-semibold';
    return <>
        <div className='flex items-center bg-base-300 h-12 px-4 space-x-2'>
            {/* <DrawerToggle></DrawerToggle> */}
            <div className='font-bold text-xl'>MedScrib</div>
            {
                currentProject && <div className="pl-8 flex items-center space-x-2">
                    <StackIcon></StackIcon>
                    <span className={`${projectTitleStyle} cursor-pointer`} onClick={showProjectDialog}>{currentProject.name}</span>
                    <button className='btn btn-xs btn-circle btn-ghost focus:outline-none'
                        onClick={() => setCurrentProject(null)}>
                        <CloseIcon></CloseIcon>
                    </button>
                </div>
            }

            <div className='flex-1'></div>
            {
                user && <>
                    <UserAvatar userName={user.full_name}></UserAvatar>
                    <button className='btn btn-sm btn-square btn-ghost'
                        onClick={onLogout}>
                        <LogoutIcon></LogoutIcon>
                    </button>
                </>
            }
        </div>
        <PageSwitcher activePageId={currentPage} pages={[
            {
                id: "Projects",
                render: () => <ProjectsPage onOpenProject={setCurrentProject}></ProjectsPage>
            },
            {
                id: "ProjectHome",
                render: () => <ProjectHome project={currentProject}></ProjectHome>
            }
        ]}>
        </PageSwitcher>
        <ProjectDialog></ProjectDialog>
        <DocumentContentDialog></DocumentContentDialog>
    </>

}