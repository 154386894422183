

import * as React from 'react'

type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const ArchiveStackIcon: React.FC<Props> = ({ className }: Props) => {
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
        className={`h-4 w-4 ${className}`}>
        <path d="M4 5H20V3H4V5ZM20 9H4V7H20V9ZM9 13H15V11H21V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V11H9V13Z"></path>
    </svg>
}
