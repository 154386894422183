
import * as React from 'react'
import { Project } from '@medscrib-core/Project';
import { ScreenTitle } from '@sleekly-ui/components/ScreenTitle';
import { List } from '@sleekly-ui/components/List';
import { PubMedArticle } from '@medscrib-core/PubMedArticle';
import { LoadProjectArticles } from '@medscrib-core/LoadProjectArticles';

import { GenerateDocx } from '@medscrib-core/GenerateDocx';
import { DocxOuptutUrl } from '@medscrib-core/DocxOuptutUrl';


import { ArticleTile } from '../article/ArticleTile';
import { generateUUID } from '@sleekly-ui/utils/uuid';
import { AlchemixFlux } from '../alchemix/Alchemix';
import { WordFileIcon } from '@sleekly-ui/icons';
import { ProjectArticles } from '@medscrib-core/ProjectArticles';
import { openDialog } from '@sleekly-ui/layout/Dialog';
import { DOCUMENT_CONTENT_DIALOG } from './DocumentContentDialog';
import { BiblioArticle } from '@medscrib-core/BiblioArticle';


// LoadProjectArticles


type Props = {
    project: Project
} & React.HTMLAttributes<HTMLDivElement>

export const DocumentCollection: React.FC<Props> = ({ project }: Props) => {

    const [loading, setLoading] = React.useState<boolean>(false);

    const [articles, setArticles] = React.useState<ProjectArticles>(null);

    const visibleDocuments = articles ? articles.selected_articles : [];

    React.useEffect(() => {
        if (project) {
            loadArticles(project);
        }
    }, [project]);

    const loadArticles = async (project: Project) => {
        setLoading(true);
        const event: LoadProjectArticles = {
            id: `${project.id}-${generateUUID()}`,
            kind: 'LoadProjectArticles',
            project_id: project.id
        };
        const res = await AlchemixFlux.postEvent("medscrib", event);
        const articles: ProjectArticles = res.getFirst("ProjectArticles")
        console.log(articles);
        setArticles(articles);
        setLoading(false);
    }

    const openArticle = (article: PubMedArticle) => {
        openDialog(DOCUMENT_CONTENT_DIALOG, article);
    }

    const articleTileRenderer = (openArticle) => (bibArticle: BiblioArticle) => {
        const article = bibArticle.article;
        // TODO : Show Bib article tile
        return <div>
            <ArticleTile article={article}
                selected={false}
                isExcluded={false}
                isIncluded={true}
                onOpenArticle={openArticle}>
            </ArticleTile>
        </div>
    }

    const exportToWord = async () => {
        const event: GenerateDocx = {
            id: `${project.id}-${generateUUID()}`,
            kind: 'GenerateDocx',
            project_id: project.id
        };
        const res = await AlchemixFlux.postEvent("medscrib", event);
        const output: DocxOuptutUrl = res.getFirst("DocxOuptutUrl")

        triggerDownload(output.url, `${project.name}.docx`);
    }

    function triggerDownload(url: string, fileName: string) {
        const a = document.createElement('a'); // Create a temporary anchor element
        a.href = url;
        a.download = fileName; // Set the desired file name for the download
        document.body.appendChild(a); // Append the anchor to the document
        a.click(); // Programmatically trigger a click
        document.body.removeChild(a); // Remove the anchor after triggering the download
    }

    return <div className='flex-1 flex flex-col p-4 overflow-hidden'>
        <ScreenTitle>
            <span>Bibliography</span>
            <span className='text-sm'> ({visibleDocuments.length})</span>
            <span className='flex-1'></span>
            <button className='btn btn-sm btn-accent'
                onClick={exportToWord}>
                <WordFileIcon></WordFileIcon>
                Export to Word Document
            </button>

        </ScreenTitle>
        <div className='flex-1 overflow-hidden flex flex-col items-center'>
            <List
                className="max-w-screen-md"
                hideBorders={true}
                loading={loading}
                items={visibleDocuments}
                renderItem={articleTileRenderer(openArticle)}
                emptyMessage={'Your collection is empty'}
                emptyMessageDetails={''}
                loadingMessage="Loading your collection"
            ></List>
        </div>
    </div>
}