import { PageSwitcher } from '@sleekly-ui/layout/PageSwitcher';
import * as React from 'react'
import { SearchPage } from '../search/SearchPage';
import { Project } from '@medscrib-core/Project';
import { DocumentCollection } from '../collection/DocumentCollection';
import { SearchIcon } from '@sleekly-ui/icons/SearchIcon';
import { ArchiveStackIcon } from '@sleekly-ui/icons';

type Props = {
    project: Project
} & React.HTMLAttributes<HTMLDivElement>

export const ProjectHome: React.FC<Props> = ({ project }: Props) => {

    const [currentPage, setCurrentPage] = React.useState<string>("Search");

    const tabs = [
        {
            page: "Search",
            icon: <SearchIcon></SearchIcon>,
            label: 'Search'
        },
        {
            page: "Bibliography",
            icon: <ArchiveStackIcon></ArchiveStackIcon>,
            label: 'Bibliography'
        }
    ]

    return <>
        <div className='flex space-x-2 bg-base-200 px-2'>
            {
                tabs.map(tab => {
                    const activeStyle = tab.page === currentPage ? 'text-primary' : '';
                    return <button key={tab.page}
                        className={`hover:opacity-75 flex items-center space-x-4 p-2 ${activeStyle}`}
                        onClick={() => setCurrentPage(tab.page)}>
                        {tab.icon}
                        <span>{tab.label}</span>
                    </button>
                })
            }


        </div>
        <PageSwitcher activePageId={currentPage} pages={[
            {
                id: "Search",
                render: () => <SearchPage project={project}></SearchPage>
            },
            {
                id: "Bibliography",
                render: () => <DocumentCollection project={project}></DocumentCollection>
            }
        ]}>
        </PageSwitcher>
    </>
}