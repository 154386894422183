import * as React from "react";

import { AlchemixFlux } from "../alchemix/Alchemix";

import { SearchInput } from '@sleekly-ui/components/SearchInput';

import { RunSearch } from "@medscrib-core/RunSearch";
import { SearchResultsSummary } from "@medscrib-core/SearchResultsSummary";
import { Project } from "@medscrib-core/Project";
import { generateUUID, hash } from "@sleekly-ui/index";
import { SearchResultsExplorer } from "./SearchResultsExplorer";
import { SearchHistory } from "./SearchHistory";
import { SearchEntrySummary } from "@medscrib-core/SearchEntrySummary";
import { ArrowLeftIcon, ChevronRightIcon, DrillDownIcon } from "@sleekly-ui/icons";


type Props = {
    project: Project
} & React.HTMLAttributes<HTMLDivElement>

export const SearchPage: React.FC<Props> = ({ project }: Props) => {

    const [searchResults, setSearchResults] = React.useState<SearchResultsSummary>(null);

    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {

    }, [project]);

    const executeNewSearch = async (terms: string) => {
        const search: RunSearch = {
            id: hash.hashString(terms),
            kind: 'RunSearch',
            project_id: project.id,
            terms: terms,
            refresh: true,
            parent_search_id: "",
            refined_terms: []
        };
        runSearchAction(search);

    }

    const runSearchAction = async (search: RunSearch) => {
        setLoading(true);
        const res = await AlchemixFlux.postEvent("medscrib", search);
        const results: SearchResultsSummary = res.getFirst("SearchResultsSummary");
        console.log(res);
        setSearchResults(results);
        setLoading(false);
    }

    const refineSearch = async (terms: string) => {
        const termsConcat = [].concat(searchResults.terms, searchResults.refined_terms, terms).join(' / ');
        const search: RunSearch = {
            id: hash.hashString(termsConcat),
            kind: 'RunSearch',
            project_id: project.id,
            terms: searchResults.terms,
            refresh: true,
            parent_search_id: searchResults.search_id,
            refined_terms: [].concat(searchResults.refined_terms, terms)
        };
        runSearchAction(search);
    }

    const runFromHistory = async (searchHistoryEntry: SearchEntrySummary) => {
        const search: RunSearch = {
            id: searchHistoryEntry.search_id,
            kind: 'RunSearch',
            project_id: project.id,
            terms: searchHistoryEntry.terms,
            refresh: false,
            parent_search_id: searchHistoryEntry.parent_search_id,
            refined_terms: searchHistoryEntry.refined_terms
        };
        await runSearchAction(search);
    }

    const refreshSearch = async () => {
        const search: RunSearch = {
            id: searchResults.search_id,
            kind: 'RunSearch',
            project_id: project.id,
            terms: searchResults.terms,
            refresh: true,
            parent_search_id: searchResults.parent_search_id,
            refined_terms: searchResults.refined_terms
        };
        await runSearchAction(search);
    }

    const exitSearchContext = () => {
        setSearchResults(null);
    }

    return <div className='flex-1 overflow-hidden flex flex-col p-2'>

        {
            !searchResults && <div className='flex justify-center my-4 w-full'>
                <SearchInput query={null} onPerformQuery={executeNewSearch} className='max-w-screen-md'
                    buttonLabel="Search"></SearchInput>

            </div>
        }
        {
            searchResults && <div className="flex items-center px-2 mb-4">
                <div className="flex-1 flex items-center space-x-2 flex-wrap">
                    <button className="btn btn-xs btn-ghost btn-square opacity-50" onClick={exitSearchContext}>
                        <ArrowLeftIcon></ArrowLeftIcon>
                    </button>
                    <div className="font-thin text-xl hover:opacity-75 cursor-pointer">
                        {searchResults.terms}
                    </div>
                    {
                        searchResults.refined_terms.length > 0 && <ChevronRightIcon></ChevronRightIcon>
                    }
                    {
                        searchResults.refined_terms.map((term, index) => {
                            const isLast = index === (searchResults.refined_terms.length - 1);
                            const activeStyle = isLast ? '' : '';
                            return <div key={term} className="flex items-center space-x-2">
                                <div className={`font-thin text-xl hover:opacity-75 cursor-pointer ${activeStyle}`}
                                >
                                    <span className="font-thin text-xl">{term}</span>
                                </div>
                                {
                                    !isLast && <ChevronRightIcon></ChevronRightIcon>
                                }
                            </div>
                        })
                    }
                </div>
                <div className='w-4/12'>
                    <SearchInput query={null} onPerformQuery={refineSearch}
                        icon={<DrillDownIcon></DrillDownIcon>}

                        buttonLabel="Refine"></SearchInput>
                </div>
            </div>
        }

        {
            (searchResults || loading) && <SearchResultsExplorer project={project}
                searchResults={searchResults}
                loading={loading}
                onRefreshSearch={refreshSearch}
                onRefineSearch={refineSearch}></SearchResultsExplorer>
        }
        {
            !(searchResults || loading) && <div className='flex-1 flex flex-col items-center my-4 w-full overflow-hidden'>
                <SearchHistory project={project} onPerformSearch={runFromHistory}></SearchHistory>
            </div>
        }
    </div>
}