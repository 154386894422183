import * as React from 'react'

type Props = {

} & React.HTMLAttributes<HTMLDivElement>

export const ArrowLeftIcon: React.FC<Props> = ({ className }: Props) => {
    const size = className ?? 'h-4 w-4';
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
        className={`${size} ${className}`}>
        <path d="M7.82843 10.9999H20V12.9999H7.82843L13.1924 18.3638L11.7782 19.778L4 11.9999L11.7782 4.22168L13.1924 5.63589L7.82843 10.9999Z"></path>
    </svg>
}